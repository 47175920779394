<template>
    <div class="page-info_list">
        <el-table :data="list" border style="width: 100%" v-loading="loading">
            <el-table-column prop="supply_company" label="公司名称" align="center" />
            <el-table-column prop="business_address_desc" label="所在城市" align="center">
                <template slot-scope="{row}">
                    {{getCodeToText(row.business_address)}}
                </template>
            </el-table-column>
            <el-table-column prop="contact_name" label="联系人"  align="center"/>
            <el-table-column prop="company_service" label="主要产品及服务" align="center" />
            <el-table-column prop="create_at" label="提交日期" align="center" />
            <el-table-column prop="status" label="状态" align="center">
                <template slot-scope="{row}">
                    <el-tag type="warning" v-if="row.status === 0 && row.save_status === 2">信息待完善</el-tag>
                    <el-tag type="success" v-else-if="row.status===3">{{ row.status | formatStatus }}</el-tag>
                    <el-tag type="info" v-else-if="row.status===4">{{ row.status | formatStatus }}</el-tag>
                    <el-tag type="warning" v-else>{{ row.status | formatStatus }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="操作" align="center">
                <template slot-scope="{row}">
                    <el-button type="text" icon="el-icon-edit" @click="$router.push({name: 'EditInformation'})">编辑</el-button>
                    <el-button type="text" icon="el-icon-edit-outline" @click="submit(row)" :disabled="row.status === 0 && row.save_status === 2">提交</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import { getSupplierInfo, submitRegiste } from "@/api";
import { regionData, CodeToText } from 'element-china-area-data'
export default {
    name:'Information',
    data(){
        return {
            loading: false,
            list:[],
        }
    },
    created(){
        this.getInfo();
    },
    filters: {
        formatStatus(status) {
            switch (parseInt(status)){
                case 0:
                    return '等待注册'
                case 1:
                    return '注册待审批'
                case 2:
                    return '更新待审批'
                case 3:
                    return '正常'
                case 4:
                    return '停用'
                case 5:
                    return '等待注册'
                case 6:
                    return '信息待完善'
            }
        }
    },
    methods: {
        getInfo(){
            this.loading = true
            getSupplierInfo().then((res)=>{
                this.list = [res.data]
            }).catch(err => {
                // console.log(err,'异常')
            }).finally(()=>{
                this.loading = false
            })
        },
        submit(){
            this.$confirm('确认提交信息去审批？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true
                submitRegiste().then((res)=>{
                    this.$message({ message:'提交成功', type: 'success'});
                    this.getInfo();
                }).catch(err => {
                    // console.log(err,'异常')
                }).finally(()=>{
                    this.loading = false
                })
            }).catch(() => {  
                // console.log("取消")
            });
            
        },
        //省市区--code转text
        getCodeToText(codeAry) {
            if(!codeAry || codeAry.length==0){
                return ''
            }
           
            return CodeToText[codeAry[0]] + "/" + CodeToText[codeAry[1]];
        },
    }
}
</script>
<style lang="scss" scoped>
.page-info_list{
    padding: 30px 50px;
}
</style>